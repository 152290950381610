import React from 'react'

import { Info } from '@mui/icons-material'
import { Grid, Tooltip } from '@mui/material'
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  Edit,
  FormDataConsumer,
  ImageField,
  Labeled,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  required,
  useTranslate,
} from 'react-admin'

import { LXField } from '../../components/LXField'
import { LXNumberField } from '../../components/LXNumberField'
import { NoDeleteEditToolbar } from '../../components/NoDeleteEditToolbar'
import { L10nHelper } from '../../helpers/L10n.helper'
import { ListHelper } from '../../helpers/List.helper'
import styles from './YoutubeChannel.module.scss'

export const YoutubechannelEdit: React.FC = props => {
  const translate = useTranslate()

  const locales = L10nHelper.getYoutubeLocales()

  return (
    <Edit {...props} mutationMode="pessimistic" redirect="show">
      <SimpleForm toolbar={<NoDeleteEditToolbar />}>
        <Grid container direction="row" spacing={2}>
          <Grid item lg={2}>
            <ImageField source="thumbnailUrl" label="" />
          </Grid>
          <Grid item direction="column" lg={10} spacing={2}>
            <Grid container item direction="row" lg={10} spacing={2}>
              <LXField
                source="name"
                label={translate('_generics.name')}
                elementType={TextField}
                labeled
              />
              <Grid item lg={3}>
                <Labeled label={translate('pages.youtubeChannels.subscribersCount')}>
                  <LXNumberField source="subscribersCount" format="large" />
                </Labeled>
              </Grid>
              <Grid item lg={3}>
                <Labeled label={translate('pages.youtubeChannels.videosCount')}>
                  <LXNumberField source="videosCount" format="large" />
                </Labeled>
              </Grid>
              <Grid item lg={3}>
                <Labeled label={translate('pages.youtubeChannels.viewsCount')}>
                  <LXNumberField source="viewsCount" format="large" />
                </Labeled>
              </Grid>
              <Grid item lg={3}>
                <SelectInput
                  source="cms"
                  choices={[
                    { id: 'Logicomix', name: 'Logicomix' },
                    { id: 'Logicomix Network', name: 'Logicomix Network' },
                    { id: 'BUMP', name: 'Bump' },
                    { id: 'endemol', name: 'BMF' },
                  ]}
                  label={translate('pages.youtubeChannels.cms')}
                  required
                />
              </Grid>
            </Grid>
            <hr style={{ width: '100%' }} />
            <Grid container item direction="row" lg={10} spacing={2}>
              <Grid item sm={12} lg={6}>
                <BooleanInput
                  source="isOwnedByLogicomix"
                  label={translate('pages.youtubeChannels.isOwnedByLogicomix')}
                />
              </Grid>
              <FormDataConsumer>
                {({ formData }) => (
                  <Grid item sm={12} lg={6}>
                    <ReferenceInput
                      source="defaultBeneficiaryId"
                      reference="beneficiaries"
                      sort={ListHelper.DEFAULT_SORT}
                      fullWidth
                    >
                      <AutocompleteInput
                        optionText="name"
                        label={translate('pages.youtubeChannels.defaultBeneficiary')}
                      />
                    </ReferenceInput>
                    {formData.defaultBeneficiaryId && (
                      <ReferenceInput
                        source="defaultProductId"
                        reference="products"
                        filterToQuery={(searchText: string) => ({
                          q: [searchText],
                          beneficiaryId: formData.defaultBeneficiaryId,
                        })}
                        sort={ListHelper.DEFAULT_SORT}
                        fullWidth
                      >
                        <AutocompleteInput
                          optionText="name"
                          label={translate('pages.youtubeChannels.defaultProduct')}
                        />
                      </ReferenceInput>
                    )}
                  </Grid>
                )}
              </FormDataConsumer>
            </Grid>
            <hr style={{ width: '100%' }} />
            <Grid container item direction="row" lg={10} spacing={2}>
              <Grid container item direction="row" lg={12} spacing={2}>
                <Grid container item sm={12} lg={6}>
                  <Grid item lg={10}>
                    <BooleanInput
                      source="isAutoMonetizationEnabled"
                      label={translate('pages.youtubeChannels.isAutoMonetizationEnabled')}
                    />
                  </Grid>
                  <Grid item lg={2} style={{ position: 'relative' }}>
                    <Tooltip
                      title={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: translate('pages.youtubeChannels.autoAdsPatternTooltip'),
                          }}
                        />
                      }
                      placement="right"
                    >
                      <Info className={styles.infoEdit} />
                    </Tooltip>
                  </Grid>
                </Grid>
                <FormDataConsumer>
                  {({ formData }) =>
                    formData.isAutoMonetizationEnabled && (
                      <>
                        <Grid item sm={12} lg={6}>
                          <TextInput
                            source="autoMonetizationPolicyId"
                            label={translate('pages.youtubeChannels.autoMonetizationPolicyId')}
                            fullWidth
                            validate={[required()]}
                          />
                        </Grid>
                        <Grid item sm={12} lg={6}>
                          <SelectInput
                            source="adsInsertionPresetId"
                            choices={[{ id: 'PATTERN_1', name: 'Pattern 1 (Europe 1)' }]}
                            label={translate('pages.youtubeChannels.adsInsertionPresetId')}
                            validate={[required()]}
                            fullWidth
                          />
                        </Grid>
                      </>
                    )
                  }
                </FormDataConsumer>
                <Grid item sm="auto" lg={6} />
              </Grid>
              {
                // TODO: Enable when available on API side
                // <Grid container item direction="row" lg={12} spacing={2}>
                //   <Grid item lg={6}>
                //     <BooleanInput source="isAutoInsertSubscribeEndScreenEnabled" label={translate('pages.youtubeChannels.isAutoInsertSubscribeEndScreenEnabled')} />
                //   </Grid>
                // </Grid>
              }
            </Grid>
            <hr style={{ width: '100%' }} />
            <Grid container item direction="row" lg={10} spacing={2}>
              <Grid container item direction="row" lg={12} spacing={2}>
                <Grid item sm={12} lg={6}>
                  <BooleanInput
                    source="isAutoTagUpdateEnabled"
                    label={translate('pages.youtubeChannels.isAutoTagUpdateEnabled')}
                  />
                </Grid>
                <FormDataConsumer>
                  {({ formData }) =>
                    formData.isAutoTagUpdateEnabled && (
                      <>
                        <Grid item sm={12} lg={6}>
                          <TextInput
                            source="autoTags"
                            label={`${translate('pages.youtubeChannels.autoTags')} (${translate(
                              'pages.youtubeChannels.autoTagsCommaSeparated',
                            )})`}
                            style={{ width: '100%' }}
                            validate={[required()]}
                            multiline
                          />
                        </Grid>
                        <Grid item sm="auto" lg={6} />
                        <Grid item sm={12} lg={6}>
                          <AutocompleteInput
                            source="autoDefaultVideoLanguage"
                            choices={locales}
                            optionText="name"
                            label={translate('pages.youtubeChannels.autoDefaultVideoLanguage')}
                            validate={[required()]}
                            fullWidth
                          />
                        </Grid>
                      </>
                    )
                  }
                </FormDataConsumer>
              </Grid>
            </Grid>
            <hr style={{ width: '100%' }} />
            <Grid container item direction="row" lg={10} spacing={2}>
              <Grid container item direction="row" lg={12} spacing={2}>
                <Grid item sm={12} lg={6}>
                  <BooleanInput
                    source="isAutoSubtitlesTranslationEnabled"
                    label={translate('pages.youtubeChannels.isAutoSubtitlesTranslationEnabled')}
                  />
                </Grid>
                <Grid item sm={12} lg={6}>
                  <FormDataConsumer>
                    {({ formData }) =>
                      formData.isAutoSubtitlesTranslationEnabled && (
                        <>
                          <AutocompleteInput
                            source="subtitleMainLanguage"
                            choices={locales}
                            optionText="name"
                            label={translate('pages.youtubeChannels.subtitleMainLanguage')}
                            validate={[required()]}
                            fullWidth
                          />
                        </>
                      )
                    }
                  </FormDataConsumer>
                  <FormDataConsumer>
                    {({ formData }) =>
                      formData.isAutoSubtitlesTranslationEnabled &&
                      formData.subtitleMainLanguage && (
                        <>
                          <AutocompleteArrayInput
                            source="subtitlesLanguages"
                            choices={locales.filter(
                              ({ id }) => formData.subtitleMainLanguage !== id,
                            )}
                            optionText="name"
                            label={translate('pages.youtubeChannels.additionalSubtitlesLanguages')}
                            validate={[required()]}
                            fullWidth
                          />
                          {console.log(formData.subtitleMainLanguage)}
                        </>
                      )
                    }
                  </FormDataConsumer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  )
}
