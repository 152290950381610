import React, { useCallback, useEffect, useState } from 'react'

import MergeTypeIcon from '@mui/icons-material/MergeType'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { Grid, Link, List, Typography } from '@mui/material'
import {
  ArrayField,
  BooleanField,
  Button,
  Datagrid,
  FunctionField,
  Labeled,
  RaRecord,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from 'react-admin'
import { useParams } from 'react-router-dom'

import { LXNumberField } from '../../components/LXNumberField'
import { API } from '../../helpers/Api.helper'
import { YOUTUBE_OWNERS_IDS } from '../../helpers/constants'
import { Artwork } from '../../types/Artwork'
import { Beneficiary } from '../../types/Beneficiary'
import { YoutubeAsset } from '../../types/YoutubeAsset'
import { YoutubeChannel } from '../../types/YoutubeChannel'
import { LXField } from './../../components/LXField'
import { ArtworksMergeModal } from './ArtworksMergeModal'

export const ArtworksShow = (): JSX.Element => {
  const [artwork, setArtwork] = useState<Artwork | undefined>()
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const params = useParams()

  // Get artworkName for App title
  useEffect(() => {
    if (!params.id) {
      return
    }

    dataProvider
      .getOne('artworks', { id: params.id as string })
      .then(res => setArtwork(res?.data as Artwork))
  }, [dataProvider, params?.id])

  return (
    <Show title={`${translate('pages.artworks._title')} / ${artwork?.name || ''}`}>
      <SimpleShowLayout>
        <ArtworksInformations
          artworkName={artwork?.name || ''}
          beneficiaryId={artwork?.product.beneficiaryId || ''}
        />
      </SimpleShowLayout>
    </Show>
  )
}

const ArtworksInformations: React.FC<{
  artworkName: Artwork['name']
  beneficiaryId: Beneficiary['id']
}> = ({ artworkName, beneficiaryId }) => {
  const translate = useTranslate()
  const refresh = useRefresh()
  const notify = useNotify()
  const record = useRecordContext()
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [isMergeArtworksModalOpen, setIsMergeArtworksModalOpen] = useState(false)
  const [youtubeChannels, setYoutubeChannels] = useState<YoutubeChannel[]>([])

  const onRefreshClaimsClicked = React.useCallback(async () => {
    try {
      setIsRefreshing(true)
      await API.post(`/api/youtubeClaims/refresh?assetId=${record.youtubeAssetId}`, {})
      refresh()
      notify(translate('pages.artworks.toast_refreshClaimsSucceeded'), {
        type: 'success',
      })
    } catch (e: any) {
      notify(`${e.response.data?.message || e.response.data}`, {
        type: 'error',
      })
    }
    setIsRefreshing(false)
  }, [notify, refresh, translate, record])

  const getYoutubeChannels = useCallback(async () => {
    if (record.id) {
      const { data: assetsData } = await API.get<YoutubeAsset[]>(
        `/api/youtubeAssets/?filter={"artworkId":["${record.id}"]}`,
      )
      const assets = new Set(assetsData.map(ad => ad.channelId))

      const { data: channels } = await API.get<YoutubeChannel[]>(
        `/api/youtubeChannels/?filter={"id":[${Array.from(assets)
          .map(a => `"${a}"`)
          .join(',')}]}`,
      )

      setYoutubeChannels(channels)
    }
  }, [record.id])

  const onCloseMergeModal = useCallback(() => {
    setIsMergeArtworksModalOpen(false)
    refresh()
  }, [refresh])

  useEffect(() => {
    getYoutubeChannels()
  }, [getYoutubeChannels])

  return (
    <Grid container direction="column" spacing={2}>
      <Grid
        lg={12}
        container
        item
        alignItems="flex-end"
        justifyContent="flex-end"
        alignContent="flex-end"
      >
        <Button
          label={translate('pages.artworks.mergeArtworks')}
          onClick={() => setIsMergeArtworksModalOpen(true)}
        >
          <MergeTypeIcon />
        </Button>
      </Grid>
      <Grid container item direction="row" lg={12} spacing={2}>
        <LXField
          source="name"
          label={translate('_generics.name')}
          elementType={TextField}
          size={6}
          labeled
        />
        <LXField
          source="name"
          label={translate('_generics.product')}
          elementType={TextField}
          size={6}
          wrappingFunction={el => (
            <ReferenceField source="productId" reference="products" link="show">
              {el}
            </ReferenceField>
          )}
          labeled
        />
        <LXField
          source="productionYear"
          label={translate('_generics.productionYear')}
          elementType={TextField}
          labeled
          size={6}
        />
        <LXField
          source="name"
          label={translate('_generics.youtubeChannels')}
          elementType={TextField}
          size={6}
          labeled
        >
          <>
            {youtubeChannels.map(ytc => (
              <Link href={`/youtubeChannels/${ytc.id}/show`} key={ytc.id}>
                <Typography variant="body2">{ytc.name}</Typography>
              </Link>
            ))}
          </>
        </LXField>
        <TabbedShowLayout>
          <Tab label={translate('_generics.youtubeAssets')}>
            <ArrayField source="youtubeAssets" label="">
              <Datagrid bulkActionButtons={false}>
                <TextField source="id" label={translate('_generics.assetId')} />
                <TextField source="name" label={translate('_generics.name')} />
                <ReferenceField
                  source="channelId"
                  reference="youtubeChannels"
                  link="show"
                  label={translate('_generics.youtubeChannel')}
                >
                  <TextField source="name" />
                </ReferenceField>
                <FunctionField
                  addLabel={false}
                  render={(subRecord: RaRecord | undefined) => {
                    const ytChannel = youtubeChannels[0]
                    const ownerId = YOUTUBE_OWNERS_IDS[ytChannel?.cms]

                    return (
                      <Link
                        href={`https://studio.youtube.com/asset/${subRecord?.id}/metadata/overview?o=${ownerId}`}
                        target="_blank"
                      >
                        <Button label={translate('pages.artworks.linkToYouTubeStudio')}>
                          <YouTubeIcon />
                        </Button>
                      </Link>
                    )
                  }}
                />
              </Datagrid>
            </ArrayField>
          </Tab>
          {record.youtubeAssetId && (
            <Tab label={translate('_generics.youtubeClaims')}>
              <Grid item lg={12}>
                <Labeled label={translate('_generics.youtubeClaims')}>
                  <ReferenceManyField
                    label={translate('_generics.youtubeClaims')}
                    reference="youtubeClaims"
                    source="youtubeAssetId"
                    target="assetId"
                    filter={{ status: 'active' }}
                    sort={{ field: 'videoViews', order: 'DESC' }}
                    perPage={1000}
                    emptyText="nope"
                  >
                    <List>
                      <Datagrid bulkActionButtons={false}>
                        <TextField
                          source="videoTitle"
                          label={translate('pages.youtubeClaims.videoTitle')}
                        />
                        <LXNumberField
                          source="videoViews"
                          format="large"
                          label={translate('pages.youtubeClaims.videoViews')}
                        />
                        <BooleanField
                          source="isPartnerUploaded"
                          label={translate('pages.youtubeClaims.isPartnerUploaded')}
                        />
                        <FunctionField
                          render={(subRecord: any) => (
                            <>
                              {subRecord?.studioInfo.claimUrl && (
                                <Link href={subRecord?.studioInfo.claimUrl} target="_blank">
                                  <Button label={translate('pages.youtubeClaims.studioClaimUrl')} />
                                </Link>
                              )}
                              {subRecord?.studioInfo.videoUrl && (
                                <Link href={subRecord?.studioInfo.videoUrl} target="_blank">
                                  <Button label={translate('pages.youtubeClaims.studioVideoUrl')} />
                                </Link>
                              )}
                              {subRecord?.videoId && (
                                <Link
                                  href={`https://www.youtube.com/watch?v=${subRecord.videoId}`}
                                  target="_blank"
                                >
                                  <Button label={translate('pages.youtubeClaims.studioVideoYTUrl')}>
                                    <YouTubeIcon />
                                  </Button>
                                </Link>
                              )}
                            </>
                          )}
                        />
                      </Datagrid>
                    </List>
                  </ReferenceManyField>
                </Labeled>
              </Grid>
              <Button
                label={translate('pages.artworks.refreshClaims')}
                onClick={onRefreshClaimsClicked}
                disabled={isRefreshing}
              />
            </Tab>
          )}
        </TabbedShowLayout>
      </Grid>
      <ArtworksMergeModal
        artworkId={record.id.toString()}
        artworkName={artworkName}
        beneficiaryId={beneficiaryId}
        isOpen={isMergeArtworksModalOpen}
        onClose={onCloseMergeModal}
      />
    </Grid>
  )
}
