import React, { Fragment, useState } from 'react'

import { CircularProgress, Grid, Typography } from '@mui/material'
import {
  BulkDeleteButton,
  Button,
  Datagrid,
  FunctionField,
  List,
  ListProps,
  SelectInput,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin'

import { LXNumberField } from '../../components/LXNumberField'
import { API } from '../../helpers/Api.helper'
import { ImportsHelper } from '../../helpers/Imports.helper'
import { LXButton } from './../../components/LXButton'
import { DateHelper } from './../../helpers/Date.helper'
import { YTImportModal } from './YTImportModal'
import { YTImportModalFromAPI } from './YTImportModalFromAPI'

const YoutubeRawImportsBulkActionButtons: React.FC = props => (
  <Fragment>
    <BulkDeleteButton {...props}>
      <span />
    </BulkDeleteButton>
  </Fragment>
)

export const YoutubeRawImportsList = (props: ListProps): JSX.Element => {
  const translate = useTranslate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalFromAPIOpen, setIsModalFromAPIOpen] = useState(false)

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={12} style={{ textAlign: 'right' }}>
          <Button
            variant="outlined"
            onClick={() => {
              setIsModalFromAPIOpen(true)
            }}
            label={translate('pages.youtubeRawImports.newReportFromAPI')}
          />
        </Grid>
        <Grid item lg={12} style={{ textAlign: 'right', paddingTop: 0 }}>
          <Button
            variant="outlined"
            onClick={() => {
              setIsModalOpen(true)
            }}
            label={translate('pages.youtubeRawImports.newReport')}
          />
        </Grid>
        <Grid item lg={12}>
          <List
            {...props}
            actions={false}
            perPage={API.DEFAULT_PAGINATION.perPage}
            sort={{ field: 'createdAt', order: 'DESC' }}
            filters={[
              <SelectInput
                key="source"
                source="source"
                choices={[
                  { id: 'Logicomix', name: 'Logicomix' },
                  { id: 'Logicomix Network', name: 'Logicomix Network' },
                  { id: 'Bump', name: 'Bump' },
                  { id: 'endemol', name: 'BMF' },
                ]}
                alwaysOn
              />,
              <SelectInput
                key="type"
                source="type"
                choices={ImportsHelper.IMPORT_TYPES}
                alwaysOn
              />,
            ]}
          >
            <Datagrid bulkActionButtons={<YoutubeRawImportsBulkActionButtons />}>
              <TextField source="source" sortable={false} />
              <FunctionField
                source="type"
                label={translate('pages.youtubeRawImports.importType')}
                render={(record: any) => (
                  <span>{translate(`pages.youtubeRawImports.importTypes.${record.type}`)}</span>
                )}
              />
              <FunctionField
                source="period"
                label={`${translate('_generics.month._title')} / ${translate('_generics.year')}`}
                render={(record: any) => (
                  <span>{`${record.month.toString().padStart(2, '0')} / ${record.year}`}</span>
                )}
              />
              <TextField
                source="conversionRateDollarToEuros"
                sortable={false}
                label={translate('pages.youtubeRawImports.conversionRateDollarToEuros')}
              />
              <LXNumberField
                source="partnerRevenueInEuros"
                format="euros"
                label={translate('pages.youtubeRawImports.totalRevenueInEuros')}
                hideLabel
              />
              <FunctionField
                source="createdAt"
                label={translate('pages.youtubeRawImports.createdAt')}
                sortable
                render={(record: any) => (
                  <span>{DateHelper.format(record.createdAt, 'DD-MM-YYYY hh:mm')}</span>
                )}
              />
              <YoutubeRawImportActions />
            </Datagrid>
          </List>
        </Grid>
      </Grid>
      <YTImportModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <YTImportModalFromAPI
        isModalOpen={isModalFromAPIOpen}
        setIsModalOpen={setIsModalFromAPIOpen}
      />
    </>
  )
}

const YoutubeRawImportActions = (): JSX.Element => {
  const translate = useTranslate()
  const record = useRecordContext()

  if (
    record.status === ImportsHelper.IMPORT_STATUSES.READY &&
    record.type !== 'ecommerce_paid_feature'
  ) {
    return (
      <LXButton
        innerLabel={translate('_generics.correct')}
        setPath={r => {
          return `/imports/${r.id}/amend${
            ['shorts_non_music_subscriptions_video', 'shorts_non_music_ads_video'].includes(r.type)
              ? 'Shorts'
              : ''
          }`
        }}
      />
    )
  }

  if (record.status === ImportsHelper.IMPORT_STATUSES.CORRECTED) {
    return (
      <Typography component="span" variant="subtitle2" color="primary">
        &#9989;&nbsp;&nbsp;{translate('pages.youtubeRawImports.treatedImport')}
      </Typography>
    )
  }

  if (record.status === ImportsHelper.IMPORT_STATUSES.ERROR) {
    return (
      <Typography component="span" variant="subtitle2" color="error">
        &#9940;&nbsp;&nbsp;{translate('pages.youtubeRawImports.error')}
      </Typography>
    )
  }

  return (
    <Typography component="span" variant="subtitle2" color="secondary">
      <CircularProgress color="secondary" size="0.7rem" />
      &nbsp;&nbsp;
      {translate('pages.youtubeRawImports.treatmentInProgress')}
    </Typography>
  )
}
