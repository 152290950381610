import React, { useCallback, useMemo, useState } from 'react'

import CsvIcon from '@mui/icons-material/ListAlt'
import { Grid, Typography } from '@mui/material'
import {
  AutocompleteInput,
  Button,
  Datagrid,
  DateField,
  FunctionField,
  Link,
  List,
  ReferenceInput,
  SelectInput,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin'

import { LXNumberField } from '../../components/LXNumberField'
import { LXStatementsActions } from '../../components/LXStatementsActions'
import { API } from '../../helpers/Api.helper'
import { DateHelper } from '../../helpers/Date.helper'
import { ListHelper } from '../../helpers/List.helper'
import { NumberFormatter } from '../../helpers/NumberFormatter.helper'
import { CMS_LIST } from '../../helpers/constants'
import styles from '../Beneficiaries/Beneficiary.module.scss'
import { CreateAllStatementModal } from './CreateAllStatementModal'
import { CreateStatementModal } from './CreateStatementModal'
import { StatementsListDownloadModal } from './StatementsListDownloadModal'

export const StatementList: React.FC = props => {
  const translate = useTranslate()
  const refresh = useRefresh()
  const notify = useNotify()
  const [isCreateStatementModalOpen, setIsCreateStatementModalOpen] = React.useState(false)

  const onCloseCreateStatementModalOpen = React.useCallback(
    (created: boolean) => {
      if (created) {
        refresh()
        notify(translate('pages.statements.createdAll'), { type: 'success' })
      }

      setIsCreateStatementModalOpen(false)
    },
    [notify, translate, refresh],
  )

  const [isCreateStatementSummaryModalOpen, setIsCreateStatementSummaryModalOpen] =
    React.useState(false)
  const [isDownloadStatementsModalOpen, setIsDownloadStatementsModalOpen] = useState(false)

  const onCloseDownloadStatementsModal = useCallback(() => {
    setIsDownloadStatementsModalOpen(false)
  }, [])

  const onCloseCreateStatementSummaryModalOpen = React.useCallback(
    (created: boolean) => {
      if (created) {
        notify(translate('pages.statements.createdSummary'), {
          type: 'success',
        })
      }

      setIsCreateStatementSummaryModalOpen(false)
      setIsCreateStatementModalOpen(false)
    },
    [notify, translate],
  )

  const periods = useMemo(() => {
    const ps = []
    let d = DateHelper.currentDate()
    const startDate = DateHelper.january2021Date()
    while (d.isAfter(startDate)) {
      const month = (d.month() + 1).toString().padStart(2, '0')
      ps.push({
        id: `${d.year()}-${month}-15`,
        name: `${month} / ${d.year()}`,
      })
      d = d.subtract(1, 'month')
    }

    return ps
  }, [])

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        alignItems="flex-end"
        alignContent="flex-end"
      >
        <Button
          variant="contained"
          label={translate('pages.statements.create')}
          onClick={() => setIsCreateStatementModalOpen(true)}
        />
        <Button
          label={translate('pages.statements.createSummary')}
          onClick={() => setIsCreateStatementSummaryModalOpen(true)}
        >
          <CsvIcon />
        </Button>
      </Grid>
      <List
        {...props}
        perPage={API.DEFAULT_PAGINATION.perPage}
        sort={{ field: 'period', order: 'DESC' }}
        exporter={false}
        filters={[
          <ReferenceInput
            key="beneficiary"
            source="beneficiaryId"
            reference="beneficiaries"
            alwaysOn
            allowEmpty={false}
            sort={ListHelper.DEFAULT_SORT}
          >
            <AutocompleteInput optionText="name" label="pages.beneficiaries._title" />
          </ReferenceInput>,
          <SelectInput
            key="cms"
            source="beneficiary.cms"
            label="pages.youtubeChannels.cms"
            choices={CMS_LIST}
            alwaysOn
            resettable
          />,
          <SelectInput
            key="period"
            source="period"
            label="_generics.period"
            choices={periods}
            alwaysOn
            resettable
          />,
        ]}
        actions={
          <Grid container justifyContent="flex-end" alignItems="flex-end" style={{ width: '50%' }}>
            <Button
              label={translate('pages.statements.downloadListStatements')}
              onClick={() => setIsDownloadStatementsModalOpen(true)}
            >
              <CsvIcon />
            </Button>
            <StatementsListDownloadModal
              isOpen={isDownloadStatementsModalOpen}
              onClose={onCloseDownloadStatementsModal}
            />
          </Grid>
        }
      >
        <Datagrid bulkActionButtons={false}>
          <FunctionField
            source="period"
            label={`${translate('_generics.month._title')} / ${translate('_generics.year')}`}
            render={(record: any) => (
              <span>{`${record.month.toString().padStart(2, '0')} / ${record.year}`}</span>
            )}
          />
          <FunctionField
            source="beneficiary.name"
            label={translate('_generics.beneficiary')}
            render={(record: any) => (
              <Link to={`/beneficiaries/${record?.beneficiaryId}/show`}>
                {record?.beneficiary.name}
              </Link>
            )}
          />
          <LXNumberField source="videosViews" format="large" />
          <LXNumberField source="youtubeGeneratedRevenue" format="euros" />
          <LXNumberField source="ugcGeneratedRevenue" format="euros" />
          <LXNumberField source="generatedRevenue" format="euros" />
          <LXNumberField source="appliedRate" format="percentRate" />
          <LXNumberField
            source="netRevenue"
            format="euros"
            label={translate('pages.statements.netRevenue')}
            hideLabel
          />
          <FunctionField
            source="adjustmentRows"
            label={translate('pages.statements.adjustment')}
            sortable={false}
            render={(record: any) => (
              <div className={styles.numberChipContainer}>
                {record?.adjustmentRows?.map((row: any) => (
                  <Typography
                    key={row.id}
                    component={'div'}
                    variant="body2"
                    className={styles.numberChip}
                  >
                    {NumberFormatter.formatEuros(row.amountInEuros)}
                  </Typography>
                ))}
              </div>
            )}
          />
          <DateField source="createdAt" />
          <LXStatementsActions />
        </Datagrid>
      </List>
      <CreateAllStatementModal
        isOpen={isCreateStatementModalOpen}
        onClose={onCloseCreateStatementModalOpen}
      />
      <CreateStatementModal
        type="statementsSummaryCSV"
        isOpen={isCreateStatementSummaryModalOpen}
        onClose={onCloseCreateStatementSummaryModalOpen}
      />
    </>
  )
}
