export const YOUTUBE_OWNERS_IDS: { [cms: string]: string } = {
  Logicomix: 'AkXOB7W-CcJiy62BsVB4vg',
  'Logicomix Network': 'yFA-9sNB2Ve3Q4pLaaI6fg',
  Endemol: 'LYpkkKtsyeENfUXYiQ8wYA',
  Bump: 'SlqNIpL-0tRhIxNNcFa2ZQ',
}

export const CMS_LIST = [
  { id: 'logicomix', name: 'Logicomix' },
  { id: 'bump', name: 'Bump' },
  { id: 'endemol', name: 'BMF' },
]

export const PAGE_SIZES = [10, 25, 50, 100]
export const DEFAULT_PAGE_SIZE = PAGE_SIZES[2]
