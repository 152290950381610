import frenchMessages from 'ra-language-french'
import { TranslationMessages } from 'react-admin'

export const frMessages: TranslationMessages = {
  ...frenchMessages,
  _generics: {
    name: 'Nom',
    address: 'Adresse',
    address2: 'Adresse (ligne 2)',
    catalog: 'Catalogue',
    from: 'de',
    to: 'à',
    fromIncluded: 'de (inclus)',
    toIncluded: 'à (inclus)',
    productionYear: 'Année de Production',
    product: 'Collection',
    productType: 'Type de collection',
    artwork: 'Oeuvre',
    code: 'Code',
    import: 'Importer',
    create: 'Créer',
    confirm: 'Confirmer',
    save: 'Sauvegarder',
    createAndAssign: 'Créer et Assigner',
    cancel: 'Annuler',
    close: 'Fermer',
    errors: 'Erreurs',
    published: 'Publiée',
    yes: 'Oui',
    no: 'Non',
    with: 'Avec',
    without: 'Sans',
    translate: 'Traduire',
    beneficiary: 'Ayant-Droit',
    youtubeClaims: 'Claims',
    date: 'Date',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    day: 'Jour',
    title: 'Titre',
    subtitles: 'Sous-titres',
    youtubeChannelId: 'ID Chaîne YouTube',
    selected: 'sélectionné(s)',
    noResult: 'Pas de résultat',
    noChannel: 'Pas de chaîne',
    groupBy: 'Grouper par',
    createdAt: 'Date de Création',
    languages: 'Langues',
    mainlanguage: 'Langue principale',
    additionalLanguages: 'Langues additionnelles',
    videos: 'Vidéos',
    links: 'Liens',
    month: {
      _title: 'Mois',
      1: 'Janvier',
      2: 'Février',
      3: 'Mars',
      4: 'Avril',
      5: 'Mai',
      6: 'Juin',
      7: 'Juillet',
      8: 'Août',
      9: 'Septembre',
      10: 'Octobre',
      11: 'Novembre',
      12: 'Décembre',
    },
    year: 'Année',
    quarter: 'Trimestre',
    semester: 'Semestre',
    youtubeChannel: 'Chaîne YouTube',
    youtubeChannels: 'Chaînes YouTube',
    youtubeAssets: 'Assets YouTube',
    assetId: 'Asset ID',
    step: 'Étape',
    next: 'Suivant',
    previous: 'Précédent',
    correct: 'Corriger',
    logo: 'Logo',
    finish: 'Finaliser',
    merge: 'Regrouper',
    update: 'Mettre à jour',
    transfer: 'Transférer',
    errorOccured: 'Une erreur est survenue',
    standard: 'Standard',
    basic: 'Simplifié',
    artworkChannel: 'Par oeuvre & chaîne',
    ok: 'Ok',
    period: 'Période',
    actions: 'Actions',
    refresh: 'Rafraîchir',
  },
  pages: {
    youtubeChannels: {
      _title: 'Chaînes YouTube',
      subscribersCount: "Nbre d'Abonnés",
      videosCount: 'Nbre de Vidéos',
      viewsCount: 'Nbre de Vues',
      cms: 'CMS',
      isOwnedByLogicomix: 'Géré par Logicomix',
      defaultBeneficiary: 'Ayant-droit associé automatiquement',
      defaultProduct: 'Collection associée automatiquement',
      linkToYouTube: 'Voir sur YouTube',
      isAutoMonetizationEnabled: 'Monétisation automatique',
      autoMonetizationPolicyId: 'PolicyID Monétisation automatique',
      isAutoTagUpdateEnabled: 'Mise à jour automatique des tags',
      isAutoSubtitlesTranslationEnabled: 'Traduction automatisée des sous-titres',
      subtitlesLanguages: 'Langues de traduction des sous-titres',
      subtitleMainLanguage: 'Langue principale de traduction',
      additionalSubtitlesLanguages: 'Langues additionnelles de traduction',
      autoTags: 'Tags automatiques',
      autoTagsCommaSeparated: 'séparés par des virgules',
      isAutoInsertSubscribeEndScreenEnabled: "Insertion automatique de l'écran de fin de vidéo",
      autoDefaultVideoLanguage: 'Langue par défaut des vidéos',
      autoAdsPatternTooltip: `Si durée < 8 minutes : </br>
        - preroll </br>
        - postroll </br></br>
      Si durée >= 8 minutes : </br>
        - preroll </br>
        - 2 min </br>
        - 7 min </br>
        - 9 min (si durée > 9 min) </br>
        - 16 min (si durée > 16 min) </br>
        - 23 / 30 / 37 / etc ... toutes les 7 min </br>
        - postroll`,
      adsInsertionPresetId: 'Identifiant de pattern de Monétisation',
    },
    beneficiaries: {
      _title: 'Ayants-Droits',
      _tabs: {
        1: 'informations',
        2: 'collections',
        3: 'taux de royautés',
        4: 'statements',
      },
      allBeneficiaries: 'Tous les ayants-droits',
      createStatement: 'Nouveau statement',
      regenerateStatements: 'Regénerer les statements sélectionnés',
      contactFirstName: 'Prénom Contact',
      contactName: 'Nom Contact',
      dearPolite: {
        title: 'Formule de politesse',
        options: {
          single_male: 'Cher',
          single_female: 'Chère',
          plural_male: 'Chers',
          plural_female: 'Chères',
        },
      },
      beneficiaryType: "Type d'Ayant-Droit",
      contactEmail: 'Adresse email contact',
      downloadCatalogCsv: 'Catalogue au format CSV',
      downloadAssetsCsv: 'Tous les assets au format CSV',
      rate: 'Taux',
      addRate: 'Ajouter un taux',
      rateInvalid:
        'Les taux ne sont pas valides : ils ne peuvent pas se superposer, et la période de début doit toujours être antérieure ou égale à la période de fin.',
      addBeneficiary: 'Ajouter un Ayant-Droit',
      imagePlaceholder: 'Glisser-déposer des images ici ou cliquer pour sélectionner une image',
      isAutoCorrected: 'Création automatique des oeuvres ?',
      defaultProductId: 'Collection à utiliser automatiquement',
      defaultProductTypeId: 'Type de collection à utiliser automatiquement',
      defaultDatavizPreset: 'Preset de performance associé',
      statementSettings: {
        title: 'Réglages des statements',
        introduction: 'Introduction',
        globalInformation: 'Informations génériques',
        firstPageLXGreetings: 'Page de garde (signée Yann)',
        firstPageLXGreetingsError: 'Incompatible les informations génériques',
        productTypes: 'Performances par type de collections',
        products: 'Performances par collections',
        artworks: 'Performances par oeuvres',
        shorts: 'Revenus provenant des shorts',
        monthlyComparison: 'PDF: Comparaison mensuelle',
        yearlyComparison: 'PDF: Comparaison annuelle',
      },
      mediaUploadGoogleDriveFolderId: 'ID du dossier Google Drive',
    },
    products: {
      _title: 'Collections',
      _tabs: {
        1: 'informations',
        2: 'œuvres',
        3: 'taux spécifiques',
      },
      productType: 'Type de Collection',
      specificRate: 'Taux Spécifique',
      specificRateExplaination:
        "Il est possible de spécifier un taux particulier pour une collection. Si une collection n'a pas de taux, alors le taux de l'ayant-droit sera utilisé.",
      beneficiary: 'Ayant-droit',
      addProduct: 'Ajouter une Collection',
      modal_transferProductBeneficiary: 'Transférer la collection vers un autre ayant-droit',
      modal_currentBeneficiary: 'Ayant-droit actuel',
      modal_impacted: {
        intro: 'Ce changement impactera',
        artworks: '%{smart_count} oeuvre |||| %{smart_count} oeuvres',
        rows: '%{smart_count} ligne des imports |||| %{smart_count} lignes des imports',
      },
      modal_noImpactedRows: "Ce changement n'impactera aucune oeuvre ni aucune ligne des imports",
      toast_transferSuccess: 'Le transfert de collection a été effectué avec succès',
      toast_transferError: 'Erreur lors du transfert de collection',
    },
    artworks: {
      _title: 'Oeuvres',
      addArtwork: 'Ajouter une Oeuvre',
      refreshClaims: 'Rafraîchir la liste des claims',
      mergeArtworks: 'Regrouper des oeuvres',
      mergeArtworksDescription:
        'Veuillez sélectionner les oeuvres que vous souhaiter rassembler avec "%{name}".',
      beneficiaryArtworks: "Oeuvres de l'ayant droit",
      idNumber: 'IDNumber / ISAN',
      modal_transferArtworkProduct: "Transférer l'oeuvre vers une autre collection",
      modal_chooseBeneficiaryToFilter:
        'Veuillez choisir un ayant-droit afin de filtrer les collections disponibles',
      modal_currentBeneficiary: 'Ayant-droit actuel',
      modal_currentProduct: 'Collection actuelle',
      modal_impactedRows:
        'Ce changement impactera %{smart_count} ligne des imports |||| Ce changement impactera %{smart_count} lignes des imports',
      modal_noImpactedRows: "Ce changement n'impactera aucune ligne des imports",
      toast_refreshClaimsSucceeded: 'La liste des claims a bien été rafraîchie',
      linkToYouTubeStudio: 'Voir sur YouTube Studio',
    },
    youtubeRawImports: {
      _title: 'Rapports de Ventes',
      newReportFromAPI: 'Importer les rapports automatiquement',
      newReport: 'Importer un nouveau rapport',
      source: 'CMS',
      importType: "Type d'import",
      importSuccess: 'Imports terminés',
      importTypes: {
        asset_summary_adj: 'Ads - ADJ Asset summary',
        asset_summary: 'Ads - Asset summary',
        red_music_asset: 'RED - Music - Rawdata Asset',
        red_non_music_asset: 'RED - Rawdata Asset',
        ecommerce_paid_feature: 'ECommerce - Paid Feature',
        shorts_non_music_subscriptions_video: 'SHORTS - Non Music - Subscriptions Video',
        shorts_non_music_ads_video: 'SHORTS - Non Music - Ads Video',
      },
      importTypesAuto: {
        content_owner_asset_ad_revenue_summary_a1: 'Ads - Asset summary',
        content_owner_music_asset_red_revenue_raw_a1: 'RED - Music - Rawdata Asset',
        content_owner_non_music_asset_red_revenue_raw_a1: 'RED - Rawdata Asset',
      },
      conversionRateDollarToEuros: 'Taux de Conversion $ > €',
      totalRevenue: 'CA $',
      totalRevenueInEuros: 'CA €',
      fileChecks: {
        badFileName:
          "Attention, le nom du fichier n'a pas l'air de correspondre aux paramètres entrés :",
        badSource: 'le CMS du fichier ne semble pas être <i>%{source}</i>',
        badDate: 'la période du fichier ne semble pas être <i>%{date}</i>',
        badFiletype: "l'export attendu est le <i>_%{fileType}_</i>",
        expectedFileName:
          "Le fichier attendu est <i>%{expectedFileName}</i>. Si vous l'avez renommé, ne tenez pas compte de ce message !",
      },
      progress_yt: {
        steps: {
          reading: 'Lecture du fichier...',
          refreshing_youtube_channels: 'Récupération des chaînes YouTube...',
          matching_existing_assets: 'Matching avec les assets existants...',
          calling_yt_api: "Récupération des assets via l'API YouTube...",
          error: "Une erreur est survenue, l'import n'a pas pu être integré.",
          success: "L'import a été effectué avec succès.",
        },
        step1: "Création d'un nouvel import Youtube",
      },
      dropzonePlaceholder:
        'Glisser-déposer des fichiers ici ou cliquer pour selectionner un fichier',
      treatedImport: 'Traitement terminé',
      error: "Erreur lors de l'import",
      treatmentInProgress: 'Traitement en cours',
      toast_importError: "Erreur lors de l'import",
      toast_importSuccess: 'Le rapport de vente a bien été importé',
      createdAt: 'Date de Création',
    },
    importsAmendment: {
      _title: 'Correction des imports',
      assetId: 'Asset ID',
      assetTitle: 'Titre Vidéo',
      showOnlyMissing: 'Afficher uniquement les éléments incomplets',
      showOnlyMissingAssetChannels: 'Afficher uniquement les éléments sans chaîne',
      createAllArtworks: 'Créer toutes les Oeuvres',
      creationInProgress: 'Création des oeuvres en cours…',
      allArtworksCreationSuccess: 'Toutes les oeuvres ont été créées avec succès',
      allArtworksCreationError: "Une erreur s'est produite lors de la création des oeuvres",
      previousAssetNoArtwork: 'Asset sans Oeuvre précedent',
      nextAssetNoArtwork: 'Asset sans Oeuvre suivant',
      subtext_amendAssetTitle: "Merci d'ajouter les titres manquants aux différentes vidéos",
      subtext_amendBeneficiary:
        "Merci d'ajouter les ayants-droits de ces nouvelles oeuvres avant de passer à l'étape suivante",
      subtext_amendProduct:
        "Merci d'ajouter les collections de ces nouvelles oeuvres avant de passer à l'étape suivante",
      subtext_amendProduct2:
        'Pour la sélection multiple, seuls des lignes partageant le même ayant-droit pourront être sélectionnées.',
      subtext_amendArtworks:
        "Merci de compléter les informations de chacunes de ces nouvelles oeuvres afin de finaliser l'import du rapport de ventes",
      toast_editTitleError: "Erreur lors de l'edition du nom",
      toast_editTitleSuccess: 'Le titre a bien été modifié',
      toast_editYoutubeChannelError: "Erreur lors de l'edition de la chaîne YouTube",
      toast_editBeneficiaryError: "Erreur lors de l'edition de l'ayant-droit",
      toast_editBeneficiarySuccess: "L'ayant-droit a bien été modifié",
      toast_editChannelError: "Erreur lors de l'edition de la chaîne",
      toast_editChannelSuccess: 'La chaîne a bien été modifiée',
      toast_editBulkBeneficiarySuccess: 'Les ayant-droits ont bien été modifiés',
      toast_addBeneficiarySuccess: "L'ayant-droit a bien été ajouté",
      toast_editProductError: "Erreur lors de l'edition de la collection",
      toast_editProductSuccess: 'La collection a bien été modifiée',
      toast_editBulkProductSuccess: 'Les collections ont bien été modifiées',
      toast_addProductSuccess: 'La collection a bien été ajoutée',
      toast_addProductError: "Erreur lors de l'ajout du collection",
      toast_editArtworkSuccess: "L'oeuvre a bien été modifiée",
      toast_editArtworkError: "Erreur lors de l'edition du l'oeuvre",
      toast_addArtworkSuccess: "L'oeuvre a bien été ajoutée",
      autoAssignBeneficiariesModal: {
        title: 'Assignation automatique des ayants-droits',
        message:
          'Les ayant-droits seront assignés d\'après les réglages "ayant-droit par défaut" sur les chaînes YouTube.',
        noPreview: 'Aucun rattachement automatique detecté.',
        previewRow:
          '<strong>%{smart_count} ligne</strong> sera assignée à <strong>%{beneficiaryName}</strong> (%{youtubeChannelName}) |||| <strong>%{smart_count} lignes</strong> seront assignées à <strong>%{beneficiaryName}</strong> (%{youtubeChannelName})',
        previewRowWithproduct:
          '<strong>%{smart_count} ligne</strong> sera assignée à <strong>%{beneficiaryName} / %{defaultProductName}</strong> (%{youtubeChannelName}) |||| <strong>%{smart_count} lignes</strong> seront assignées à <strong>%{beneficiaryName} / %{defaultProductName}</strong> (%{youtubeChannelName})',
        toast_autoAssignBeneficiariesSuccess:
          "L'assignation des ayants-droits a été effectuée avec succès",
        toast_autoAssignBeneficiariesError:
          "Erreur lors de l'assignation automatique des ayants-droits",
      },
      autoAssignProductsModal: {
        title: 'Assignation automatique des Collections',
        description:
          'Les collections seront automatiquement assignées d\'après les réglages "Collection par défaut" et/ou "Type de collection par défaut" sur les Ayants-Droits.',
        noPreview: 'Aucun rattachement automatique detecté.',
        footNote: "Les oeuvres seront crées automatiquement lors de l'assignation",
        rowWithDefaultProduct:
          '<strong>%{smart_count} oeuvre</strong> sera créée et assignée à la collection <strong>%{defaultProductName}</strong> (%{beneficiaryName}) |||| <strong>%{smart_count} oeuvres</strong> seront créées et assignées à la collection <strong>%{defaultProductName}</strong> (%{beneficiaryName})',
        rowWithoutDefaultProduct:
          '<strong>%{smart_count} collection</strong> et <strong>%{smart_count} oeuvre</strong> de type <strong>%{defaultProductTypeName}</strong> seront créées et rattachées à <strong>%{beneficiaryName}</strong> |||| <strong>%{smart_count} collections</strong> et <strong>%{smart_count} oeuvres</strong> de type <strong>%{defaultProductTypeName}</strong> seront créées et rattachées à <strong>%{beneficiaryName}</strong>',
        toast_autoAssignProductsSuccess:
          "L'assignation et la création des collections et des oeuvres a été effectuée avec succès",
        toast_autoAssignProductsError: "Erreur lors de l'assignation automatique des collections",
      },
    },
    statements: {
      _title: 'Statements',
      create: 'Créer les statements',
      createAll: 'Créer les statements pour tous les ayants-droits',
      createdAll: 'Les statements ont été créés',
      createSummary: 'Télécharger le récapitulatif par ayant-droit',
      createdSummary: 'Récapitulatif créé',
      regenerateStatements: 'Regénérer des statements',
      downloadListStatements: 'Télécharger les statements de la liste',
      downloadingStatements: 'Téléchargement des statements…',
      downloadingStatements_intro:
        'Lancer le téléchargement de %{count} statements, chacun au format suivant:',
      downloadingStatements_generate: 'Lancer le téléchargement',
      downloadingStatements_description:
        "Vos statements sont actuellement en cours de génération, ils seront prêts d'ici quelques instants.",
      downloadingStatements_success: 'Statements téléchargés',
      downloadingStatements_queued: "En file d'attente...",
      downloadingStatements_generating: 'Génération en cours...',
      netRevenue: 'Revenu Net',
      adjustment: 'Ajustements',
      statementAdjustmentRows: "Lignes d'ajustement",
      addAdjustmentRow: "Ajouter une ligne d'ajustement",
      adjustmentRow: {
        label: 'Label',
        amountInEuros: 'Montant en €',
      },
      created: 'Le statement a été créé',
      regenerated: 'Les statements ont bien été regénérés',
      export: 'Exports',
      pdf: 'PDF',
      csv: 'CSV',
      periodicStatementDownloads: 'Téléchargement de statements par période',
      noStatementCreated: "Veuillez créer un premier statement afin d'accéder à ces rapports",
      monthByMonth: 'Mois par mois',
      videosViews: 'Nombre de vues',
      youtubeGeneratedRevenue: 'Revenus Officiels (en euros)',
      ugcGeneratedRevenue: 'Revenus UGC (en euros)',
      generatedRevenue: 'Revenus (en euros)',
      appliedRate: 'Taux appliqué',
      beneficiaryRevenueInEuros: 'Royautés ayant-droit (en euros)',
    },
    youtubeClaims: {
      videoTitle: 'Titre de la video',
      studioClaimsUrl: 'Voir les claims (studio)',
      studioClaimUrl: 'Lien vers le claim (studio)',
      studioVideoUrl: 'Lien vers la vidéo (studio)',
      studioVideoYTUrl: 'Lien vers la vidéo (YT)',
      videoViews: 'Nombre de vues',
      isPartnerUploaded: 'Mis en ligne par le partenaire',
    },
    analytics: {
      _title: 'Performances',
      selectBeneficiary: 'Sélectionnez un/des ayant(s)-droits',
      selectCMS: 'Sélectionnez un/des CMS',
      selectProduct: 'Sélectionnez une/des collection(s)',
      selectProductType: 'Sélectionnez un/des type(s) de collection',
      selectArtwork: 'Sélectionnez une/des oeuvre(s)',
      selectRevenueTypes: 'Sélectionnez un/des type(s) de revenu',
      selectYoutubeChannels: 'Sélectionnez une/des chaîne(s) Youtube',
      selectMetric: 'Sélectionnez une donnée à afficher',
      videos: 'Videos',
      presets: 'Presets',
      savePreset: 'Sauvegarder un preset',
      presetName: 'Nom du preset',
      metric: 'Donnée',
      revenueType: 'Type de revenu',
      groupBy: {
        noGroupBy: 'Pas de groupe',
        productId: 'Collections',
        productTypeId: 'Types de collection',
        artworkId: 'Oeuvres',
        youtubeAssetChannelId: 'Chaînes Youtube',
        beneficiaryId: 'Ayants-droit',
        cms: 'CMS',
        countryCode: 'Pays',
        revenueType: 'Type de revenu',
      },
      revenueTypes: {
        shorts_non_music_subscriptions: 'SHORTS - Non Music - Subscriptions',
        shorts_non_music_ads: 'SHORTS - Non Music - Ads',
        ecommerce: 'Ecommerce',
        ad: 'Ad',
        music_red: 'RED Music',
        nonmusic_red: 'RED Non-Music',
      },
      defineAsDefaultPreset: "Définir comme preset par défaut pour l'ayant-droit",
      metrics: {
        'analytics.comments': 'Commentaires',
        'analytics.dislikes': 'Dislikes',
        'analytics.likes': 'Likes',
        'analytics.redWatchTimeMinutes': 'Durée de visionnage RED (en minutes)',
        'analytics.shares': 'Partages',
        'analytics.views': 'Vues',
        'analytics.watchTimeMinutes': 'Durée de visionnage (en minutes)',
        'revenues.partnerRevenuePerAsset': 'Revenus YouTube (en euros)',
        'revenues.partnerRevenuePerAssetPartnerProvided': 'Revenus YouTube Officiels (en euros)',
        'revenues.partnerRevenuePerAssetUGC': 'Revenus YouTube UGC (en euros)',
        'statements.beneficiaryRevenueInEuros': 'Royautés ayant-droit (en euros)',
        'statements.revenueInEuros': 'Revenus (en euros)',
        'statements.revenuePartnerProvidedInEuros': 'Revenus Officiels (en euros)',
        'statements.revenueUGCInEuros': 'Revenus UGC (en euros)',
        'statementAdditionals.beneficiaryRevenueInEuros': 'Royautés additionnels (en euros)',
        'statementAdditionals.revenueInEuros': 'Revenus additionnels (en euros)',
      },
      metricsSections: {
        analytics: 'Performances',
        revenues: 'Revenus',
        statements: 'Statements',
        statementAdditionals: 'Additionnels',
      },
      units: {
        comments: 'commentaires',
        dislikes: 'dislikes',
        likes: 'likes',
        redWatchTimeMinutes: 'minutes',
        watchTimeMinutes: 'minutes',
        shares: 'partages',
        views: 'vues',
      },
      exportToCsv: 'Exporter en CSV',
      toast_exportToCsvError: "L'export en CSV a échoué",
      toast_createPresetSuccess: 'Le preset a été créé avec succès',
      toast_createPresetError: 'Erreur lors de la création de preset',
      toast_setDefaultPresetSuccess:
        'Le preset a été assigné à %{beneficiaryName} par défaut avec succès',
      toast_setDefaultPresetError: "Erreur lors de l'assignation par défaut de preset",
      modal_confirmSetDefaultPreset:
        "Êtes-vous sûr de vouloir assigner le preset %{presetName} à l'ayant-droit %{beneficiaryName} ?",
    },
    automatedTreatments: {
      _title: 'Traitements auto.',
      monetization: 'Monétisation',
      translation: 'Traduction',
      monetizationAssetId: 'Monétisation Asset ID',
      monetizedAt: 'Date de monétisation',
      insertedTags: 'Tag insérés',
      insertedTagsAt: "Date d'insertion des tags",
      publishedOnYoutubeAt: 'Date de publication',
      titleTranslation: 'Traduction titre',
      descriptionTranslation: 'Traduction description',
      subtitlesTranslation: 'Traduction sous-titres',
      translatedAt: 'Date de traduction',
      translationError: 'Erreurs de traduction',
      translateSubtitles: 'Traduire les sous-titres',
      subtitlesTranslatedForVideos:
        "Les sous-titres seront traduits pour toutes les vidéos de la chaine sélectionnée (ou toutes les vidéos des chaînes ayant l'option activée).",
      error: 'Erreur',
      numberOfLanguages: '%{smart_count} langues',
      progressModal: {
        title: 'Traduction des titres, descriptions et sous-titres',
        inProgress: 'Traitement en cours',
        success: 'Traitement terminé',
        error: 'Une erreur est survenue',
      },
    },
  },
  errors: {
    error__duplicate_beneficiary_code: 'Erreur - Code ayant-droit déjà utilisé',
  },
}
