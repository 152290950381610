import React from 'react'

import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  CreateButton,
  Datagrid,
  ImageField,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
  TopToolbar,
  useTranslate,
} from 'react-admin'

import { LXNumberField } from '../../components/LXNumberField'
import { API } from '../../helpers/Api.helper'
import { ListHelper } from '../../helpers/List.helper'

const YoutubeChannelListActions = (): JSX.Element => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
)

export const YoutubechannelList: React.FC = props => {
  const translate = useTranslate()

  return (
    <List
      {...props}
      actions={<YoutubeChannelListActions />}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={API.DEFAULT_PAGINATION.perPage}
      exporter={false}
      filterDefaultValues={{ isOwnedByLogicomix: true }}
      filters={[
        <SearchInput key="search" source="q" alwaysOn />,
        <SelectInput
          key="cms"
          source="cms"
          choices={[
            { id: 'Logicomix', name: 'Logicomix' },
            { id: 'Logicomix Network', name: 'Logicomix Network' },
            { id: 'BUMP', name: 'Bump' },
            { id: 'endemol', name: 'BMF' },
          ]}
          alwaysOn
        />,
        <ReferenceInput
          key="defaultBeneficiaryId"
          source="defaultBeneficiaryId"
          reference="beneficiaries"
          alwaysOn
          allowEmpty={false}
          sort={ListHelper.DEFAULT_SORT}
        >
          <AutocompleteInput optionText="name" label="_generics.beneficiary" />
        </ReferenceInput>,
        <BooleanInput
          key="isOwnedByLogicomix"
          source="isOwnedByLogicomix"
          alwaysOn
          label={translate('pages.youtubeChannels.isOwnedByLogicomix')}
        />,
      ]}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <ImageField source="thumbnailUrl" sortable={false} label={''} />
        <TextField source="name" label={translate('_generics.name')} />
        <LXNumberField
          source="subscribersCount"
          format="large"
          label={translate('pages.youtubeChannels.subscribersCount')}
          hideLabel
        />
        <LXNumberField
          source="videosCount"
          format="large"
          label={translate('pages.youtubeChannels.videosCount')}
          hideLabel
        />
        <LXNumberField
          source="viewsCount"
          format="large"
          label={translate('pages.youtubeChannels.viewsCount')}
          hideLabel
        />
        <TextField source="cms" sortable={false} label={translate('pages.youtubeChannels.cms')} />
        <BooleanField
          source="isOwnedByLogicomix"
          sortable={false}
          label={translate('pages.youtubeChannels.isOwnedByLogicomix')}
        />
        <ReferenceField
          source="defaultBeneficiaryId"
          reference="beneficiaries"
          label={translate('pages.youtubeChannels.defaultBeneficiary')}
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="defaultProductId"
          reference="products"
          label={translate('pages.youtubeChannels.defaultProduct')}
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  )
}
